"use client"

import { Button } from "@/components/ui/button"

export function ScrollButton() {
  const handleScrollTo = () => {
    const footer = document.getElementById("footer")
    if (footer) {
      footer.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <Button variant="tertiary" className="cursor-pointer  rounded-lg px-4 py-2 font-medium text-white sm:px-6" asChild>
      <div className="text-white" onClick={handleScrollTo}>
        ติดต่อเรา
      </div>
    </Button>
  )
}
